import {
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonInput,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useMaskito } from "@maskito/react";
import { formatISODateString } from "../extras/Functions";
import { API_URL } from "../../actions/settings";
import DegreeDaysLookupTable from "./DegreeDaysLookupTable";
import NotificationPopUp from "../extras/NotificationPopUp";

// interface Props {}

async function getData(
  token: string,
  start_date: string,
  end_date: string,
  zip_code: string
) {
  return fetch(
    `${API_URL}/degree_days_lookup/?code=${zip_code}&start_date=${start_date}&end_date=${end_date}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      NotificationPopUp("error", "No Data Found");
      return false;
    }
  });
}

const lastday = function (y: any, m: any) {
  // By passing m + 1 as the month parameter and 0 as the day parameter, it represents the last day of the specified month
  return new Date(y, m + 1, 0);
};

const DegreeDaysLookup: React.FC = () => {
  const start_datetime = useRef<null | HTMLIonDatetimeElement>(null);
  const end_datetime = useRef<null | HTMLIonDatetimeElement>(null);
  const [zipCode, setZipCode] = useState("");
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState([]);

  const zipCodeMask = useMaskito({
    options: {
      mask: [...Array(6).fill(/\d/)],
    },
  });

  useEffect(() => {
    const d = new Date();
    const t = new Date(); // todays date
    const sd = new Date(new Date(d.setMonth(d.getMonth() - 3)).setDate(1)); // 3 months ago, first day
    if (start_datetime.current) {
      start_datetime.current.value = sd.toISOString();
    }
    if (end_datetime.current) {
      end_datetime.current.value = lastday(
        t.getFullYear(),
        t.getMonth()
      ).toISOString();
    }
  }, []);

  const submit = async () => {
    setError(null);
    const start_date =
      formatISODateString(String(start_datetime.current?.value)) || "";
    const end_date =
      formatISODateString(String(end_datetime.current?.value)) || "";
    const zip_code = zipCode;
    let valid = true;

    if (zip_code == null || zip_code === "") {
      setError("Missing Zip Code");
      valid = false;
    }
    if (zip_code.length > 0 && zip_code.length < 5) {
      setError("Incorrect Zip Code");
      valid = false;
    }
    if (
      new Date(String(start_datetime.current?.value)) >
      new Date(String(end_datetime.current?.value))
    ) {
      setError("Invalid Dates");
      valid = false;
    }
    if (valid) {
      const token = `Token ${(localStorage.getItem("token") || "").slice(
        1,
        -1
      )}`;

      const res = await getData(token, start_date, end_date, zip_code);
      if (res) setData(res.data);
    }
  };

  return (
    <IonRow class="ion-justify-content-center">
      <h3 style={{ width: "100%", textAlign: "center" }}>
        Look Up Degree Days
      </h3>
      <div style={{ width: "100%", textAlign: "center" }}>
        Enter Start Date, End Date, and Zip code to look up degree days.
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <form
          style={{
            maxWidth: "400px",
            border: "white solid 2px",
            marginTop: "15px",
            backgroundColor: "white",
            color: "black",
          }}
          action="none"
        >
          <IonText color="danger">{error}</IonText>
          <IonRow className="ion-justify-content-between">
            <h5>Start Date </h5>
            <IonDatetimeButton datetime="start_date"></IonDatetimeButton>
          </IonRow>
          <hr style={{ backgroundColor: "black" }}></hr>
          <IonRow className="ion-justify-content-between">
            <h5>End Date </h5>
            <IonDatetimeButton datetime="end_date"></IonDatetimeButton>
          </IonRow>
          <hr style={{ backgroundColor: "black", marginBottom: "15px" }}></hr>
          <IonRow className="ion-justify-content-center">
            <IonInput
              ref={async (zipInput) => {
                if (zipInput) {
                  const input = await zipInput.getInputElement();
                  zipCodeMask(input);
                }
              }}
              value={zipCode}
              onIonInput={(e) => setZipCode(e.detail.value || "")}
              autocomplete="postal-code"
              fill="outline"
              labelPlacement="floating"
              placeholder="xxxxx"
            >
              <div slot="label">
                ZIP CODE <IonText color="danger">(Required)</IonText>
              </div>
            </IonInput>
          </IonRow>
          <IonButton color={"medium"} onClick={submit}>
            Look up Degree Days
          </IonButton>
        </form>
      </div>

      {data.length > 0 ? (
        <div
          style={{
            maxHeight: "1000px",
            overflowY: "auto",
            marginTop: "20px",
            marginBottom: "25px",
          }}
        >
          <DegreeDaysLookupTable data={data} />
        </div>
      ) : (
        ""
      )}
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          ref={start_datetime}
          id="start_date"
          presentation="date"
        ></IonDatetime>
      </IonModal>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          id="end_date"
          ref={end_datetime}
          presentation="date"
        ></IonDatetime>
      </IonModal>
    </IonRow>
  );
};

export default DegreeDaysLookup;
